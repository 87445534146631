// src/features/buyer/buyerSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axiosInstance from '../../../api/axiosInstance';
import { Currency, CurrencyState } from './currencyInterface';
import { PaginationObject } from '../../../app/globalType';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../app/paginationConstants';
import { FormField } from '../../../components/componentInterface';

const initialState: CurrencyState = {
  currencies: [],
  currency: {
    name: '',
    code: '',
    symbol: '',
    conversion_rate: 0,
    data: {}
  },
  paginationObject: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 1,
    last_page: 1,
    last_page_url: '',
    links: [],
    next_page_url: '',
    path: '',
    per_page: 10,
    prev_page_url: '',
    to: 1,
    total: 0,
  },
  loading: false,
  error: null,
  status: 'idle',
  message: ""
};

// Async thunk for fetching buyers
export const fetchCurrencies = createAsyncThunk(
  'currency/fetchCurrencies',
  async ({ page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE }: { page?: number; perPage?: number }, { rejectWithValue }) => {

    try {
      const response = await axiosInstance.get(`/currency-list?page=${page}&perPage=${perPage}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchAllCurrencies = createAsyncThunk(
  'currency/fetchAllCurrencies',
  async () => {

    try {
      const response = await axiosInstance.get(`/currency-list-all`);
      return response.data;
    } catch (error: any) {
      return Error(error.response?.data?.message || error.message);
    }
  }
);

export const fetchCurrency = createAsyncThunk(
  'currency/fetchCurrency',
  async ({ id }: { id: string }, { rejectWithValue }) => {

    try {
      const response = await axiosInstance.get(`/currency-show/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for creating a buyer
export const createCurrency = createAsyncThunk(
  'currency/createCurrency',
  async (formState: Omit<Currency, 'id'>, { rejectWithValue }) => {
    try {

      const { details, name, code, symbol, conversion_rate,
        ...formStateData } = formState.data;

      const attributeData = {
        name: name.value,
        code: code.value,
        symbol: symbol.value,
        conversion_rate: conversion_rate.value,
      };

      const response = await axiosInstance.post('/currency-save', attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for updating a session
export const updateCurrency = createAsyncThunk(
  'currency/updateCurrency',
  async (formState: Omit<Currency, 'id'>, { rejectWithValue }) => {
    try {
      debugger
      const { details, id, name, code, symbol, conversion_rate,
        ...formStateData } = formState.data;

      const attributeData = {
        id: id.value,
        name: name.value,
        code: code.value,
        symbol: symbol.value,
        conversion_rate: conversion_rate.value,
      };
      debugger

      const response = await axiosInstance.put(`/currency-update/${attributeData.id}`, attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for deleting a session
export const deleteCurrency = createAsyncThunk(
  'currency/deleteCurrency',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/currency-delete/${id}`);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const attributeSlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    resetForm(state) {
      Object.assign(state, initialState);
    },
    resetMessage(state) {
      state.message = "";
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrencies.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCurrencies.fulfilled, (state, action: PayloadAction<PaginationObject<Currency>>) => {
        state.paginationObject = action.payload;
        state.loading = false;
      })
      .addCase(fetchCurrencies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchAllCurrencies.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllCurrencies.fulfilled, (state, action: PayloadAction<Currency[]>) => {
        state.currencies = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllCurrencies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchCurrency.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCurrency.fulfilled, (state, action: PayloadAction<Currency>) => {
        state.currency = action.payload;
        state.loading = false;
      })
      .addCase(fetchCurrency.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(createCurrency.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCurrency.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload
      })
      .addCase(createCurrency.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(updateCurrency.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCurrency.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(updateCurrency.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(deleteCurrency.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCurrency.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(deleteCurrency.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

  },
});

export const { resetForm, resetMessage, resetError } = attributeSlice.actions;

export default attributeSlice.reducer;
