import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from '../../api/axiosInstance';
import { RootState } from '../../app/store';

export interface MenuItem {
  id: number;
  name: string;
  icon: string;
  route?: string;
  child?: SubmenuItem[];
}

export interface SubmenuItem {
  id: number;
  name: string;
  icon: string;
  route?: string;
  active?: boolean;
}

export interface ModuleItem {
  module_id: number;
  module_sort_no: number;
  module_name: string;
  route: string;
}

export interface MenuState {
  menuItems: MenuItem[];
  moduleItems: ModuleItem[];
  loading: boolean;
  error: string | null;
}

const initialState: MenuState = {
  menuItems: [],
  moduleItems: [],
  loading: false,
  error: null,
};

// Async thunk for fetching menu list
export const fetchMenuItems = createAsyncThunk('menu/fetchMenuItems', async ({ firstSegment }: { firstSegment: string }) => {
  try {
    const response = await axiosInstance.get('/user-menus/?first_segment=' + firstSegment); // Replace with your API endpoint
    return response.data; // Assuming your API returns an array of menu items
  } catch (error: any) {
    throw Error(error.response?.data?.message || error.message);
  }
});

// Async thunk for fetching module list
export const fetchModuleItems = createAsyncThunk('menu/fetchModuleItems', async () => {
  try {
    const response = await axiosInstance.get('/user-modules'); // Replace with your API endpoint
    return response.data; // Assuming your API returns an array of menu items
  } catch (error: any) {
    throw Error(error.response?.data?.message || error.message);
  }
});


const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
   
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMenuItems.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMenuItems.fulfilled, (state, action: PayloadAction<MenuItem[]>) => {
        state.loading = false;
        state.menuItems = action.payload;
      })
      .addCase(fetchMenuItems.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch menu items';
      })
      .addCase(fetchModuleItems.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchModuleItems.fulfilled, (state, action: PayloadAction<ModuleItem[]>) => {
        state.loading = false;
        state.moduleItems = action.payload; // Update state with module items
      })
      .addCase(fetchModuleItems.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch module items';
      });
  },
});

// Export the slice actions and reducer
export const selectMenuItems = (state: RootState) => state.menu.menuItems; // Selector to access menu items

export const selectModuleItems = (state: RootState) => state.menu.moduleItems; // Selector to access module items


export default menuSlice.reducer;
