// src/app/store.ts
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import authReducer from '../modules/auth/authSlice';
import menuReducer from '../modules/auth/menuSlice';

import attributeReducer from '../modules/ecommerce/attribute/attributeSlice';
import categoryReducer from '../modules/ecommerce/category/categorySlice';
import attributeGroupReducer from '../modules/ecommerce/attributeGroup/attributeGroupSlice';
import productReducer from '../modules/ecommerce/product/productSlice';
import attributeFamilyReducer from '../modules/ecommerce/attributeFamily/attributeFamilySlice';
import vendorReducer from '../modules/ecommerce/vendor/vendorSlice';
import currencyReducer from '../modules/ecommerce/currency/currencySlice';
import homeSliderReducer from '../modules/ecommerce/homeSlider/homeSliderSlice';
import orderReducer from '../modules/ecommerce/orderList/orderListSlice';
import courierReducer from '../modules/ecommerce/courier/courierSlice';
import shipmentReducer from '../modules/ecommerce/shipment/shipmentSlice';
import customerReducer from '../modules/ecommerce/customer/customerSlice';
import footerLinkPagesReducer from '../modules/ecommerce/footerLinkPages/footerLinkPagesSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    menu: menuReducer,
    attribute: attributeReducer,
    category: categoryReducer,
    attributeGroup: attributeGroupReducer,
    product: productReducer,
    attributeFamily: attributeFamilyReducer,
    vendor: vendorReducer,
    currency: currencyReducer,
    homeSlider: homeSliderReducer,
    order: orderReducer,
    courier: courierReducer,
    shipment: shipmentReducer,
    customer: customerReducer,
    footerLink: footerLinkPagesReducer
    // Add other reducers as needed
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

// Define the RootState type based on the store's reducer
export type RootState = ReturnType<typeof store.getState>;

// Define AppDispatch type based on the store's dispatch
export type AppDispatch = typeof store.dispatch;

export default store;
