import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';

type Theme = 'light' | 'dark';

type MenuColor = 'colorLightCoral'
  | 'colorLimeGreen' | 'colorDarkTurquoise' | 'colorRoyalBlue' | 'colorSilver' | 'colorDefault' | 'colorDarkOrange';

type TopNavColor = 'colorSilver' | 'colorDefault';

type TextColor = 'colorBlack' | 'colorWhite';

type HoverBGColor = 'colorBlack' | 'colorWhite';

type HoverTextColor = 'colorBlack' | 'colorWhite';

type NavigationStyle = 'Vertical' | 'Horizontal';

type BrandingTextColorHorizontalNavigation = 'colorBlack' | 'colorWhite';


interface ThemeContextProps {
  theme: Theme;
  menuColor: MenuColor;
  topNavColor: TopNavColor;
  textColor: TextColor;
  hoverBGColor: HoverBGColor;
  hoverTextColor: HoverTextColor;
  navigationStyle: NavigationStyle;
  brandingTextColorHorizontalNavigation: BrandingTextColorHorizontalNavigation;
  toggleTheme: () => void;
  setThemeColor: (color: Theme) => void;
  setMenuColor: (color: MenuColor) => void;
  setTopNavColor: (color: TopNavColor) => void;
  setTextColor: (color: TextColor) => void;
  setHoverBGColor: (color: HoverBGColor) => void;
  setHoverTextColor: (color: HoverTextColor) => void;
  setNavigationStyle: (style: NavigationStyle) => void;
  setBrandingTextColorHorizontalNavigation: (color: BrandingTextColorHorizontalNavigation) => void;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

const storedTheme = localStorage.getItem('themeColor') || 'light';
const storedMenuColor = localStorage.getItem('menuColor') || 'light';
const storedTopNavColor = localStorage.getItem('topNavColor') || 'light';
const storedTextColor = localStorage.getItem('textColor') || 'colorBlack';
const storedHoverBGColor = localStorage.getItem('hoverBGColor') || 'colorBlack';
const storedHoverTextColor = localStorage.getItem('hoverTextColor') || 'colorBlack';
const storedNavigationStyle = localStorage.getItem('navigationStyle') || 'Vertical';
const storedBrandingTextColorHorizontalNavigation = localStorage.getItem('brandingTextColorHorizontalNavigation') || 'colorBlack';

const initialTheme: Theme = (storedTheme as Theme);
const initialMenuColor: MenuColor = (storedMenuColor as MenuColor);
const initialTopNavColor: TopNavColor = (storedTopNavColor as TopNavColor);
const initialTextColor: TextColor = (storedTextColor as TextColor);
const initialHoverBGColor: HoverBGColor = (storedHoverBGColor as HoverBGColor);
const initialHoverTextColor: HoverTextColor = (storedHoverTextColor as HoverTextColor);
const initialNavigationStyle: NavigationStyle = (storedNavigationStyle as NavigationStyle);
const initialBrandingTextColorHorizontalNavigation: BrandingTextColorHorizontalNavigation 
= (storedBrandingTextColorHorizontalNavigation as BrandingTextColorHorizontalNavigation);

export const ThemeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

  const [theme, setTheme] = useState<Theme>(initialTheme);
  const [menuColor, setMenu] = useState<MenuColor>(initialMenuColor);
  const [topNavColor, setTopNav] = useState<TopNavColor>(initialTopNavColor);
  const [textColor, setTextClr] = useState<TextColor>(initialTextColor);
  const [hoverBGColor, setHoverBGClr] = useState<HoverBGColor>(initialHoverBGColor);
  const [hoverTextColor, setHoverTextClr] = useState<HoverBGColor>(initialHoverTextColor);
  const [navigationStyle, setNavigationStyl] = useState<NavigationStyle>(initialNavigationStyle);
  const [brandingTextColorHorizontalNavigation, setBrandingTextColorHorizontalNavigat] 
  = useState<BrandingTextColorHorizontalNavigation>(initialBrandingTextColorHorizontalNavigation);
  
  useEffect(() => {
    const root = window.document.documentElement;
    root.className = ''; // Remove all existing class names
    root.classList.add(theme);
  }, [theme]);

  useEffect(() => {

  }, [menuColor]);

  const toggleTheme = () => {
    setTheme((prevTheme) => {
      const newTheme = prevTheme === 'light' ? 'dark' : 'light';
      localStorage.setItem('themeColor', newTheme);
      return newTheme;
    });
  };

  const setThemeColor = (color: Theme) => {
    setTheme(color);
    localStorage.setItem('themeColor', color);
  };

  const setMenuColor = (color: MenuColor) => {
    setMenu(color);
    localStorage.setItem('menuColor', color);
  };

  const setTopNavColor = (color: TopNavColor) => {
    setTopNav(color);
    localStorage.setItem('topNavColor', color);
  };

  const setTextColor = (color: TextColor) => {
    setTextClr(color);
    localStorage.setItem('textColor', color);
  };

  const setHoverBGColor = (color: HoverBGColor) => {
    setHoverBGClr(color);
    localStorage.setItem('hoverBGColor', color);
  };

  const setHoverTextColor = (color: HoverTextColor) => {
    setHoverTextClr(color);
    localStorage.setItem('hoverTextColor', color);
  };

  const setNavigationStyle = (style: NavigationStyle) => {
    setNavigationStyl(style);
    localStorage.setItem('navigationStyle', style);
  };

  const setBrandingTextColorHorizontalNavigation = (color: BrandingTextColorHorizontalNavigation) => {
    setBrandingTextColorHorizontalNavigat(color);
    localStorage.setItem('brandingTextColorHorizontalNavigation', color);
  };

  return (
    <ThemeContext.Provider value={{
      theme, menuColor, topNavColor, textColor, hoverBGColor, hoverTextColor, navigationStyle,
      brandingTextColorHorizontalNavigation,
      toggleTheme, setThemeColor,
      setMenuColor, setTopNavColor, setTextColor, setHoverBGColor, setHoverTextColor, setNavigationStyle,
      setBrandingTextColorHorizontalNavigation
    }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};
