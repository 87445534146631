// src/routes.tsx
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import SkeletonLoader from './components/Skeleton';

const NotFound = lazy(() => import('./components/NotFound'));
const AuthRoutes = lazy(() => import('./modules/auth/authRoutes'));
const DashboardRoutes = lazy(() => import('./modules/dashboard/dashboardRoutes'));
const EcommerceRoutes = lazy(() => import('./modules/ecommerce/ecommerceRoutes'));

const AppRoutes: React.FC = () => (
  <Router>
    <Suspense fallback={<SkeletonLoader />}>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/*" element={<AuthRoutes />} />
        <Route
          path="/dashboard/*"
          element={
            <AuthGuard>
              <DashboardRoutes />
            </AuthGuard>
          }
        />

        <Route
          path="/ecommerce/*"
          element={
            <AuthGuard>
              <EcommerceRoutes />
            </AuthGuard>
          }
        />



        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  </Router>
);

export default AppRoutes;
