// src/api/axiosInstance.ts
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { getCancelTokenSource } from './cancelTokenManager';
// Define the routes to exclude
const excludedRoutes = [
  '/login',
  '/register',
  // Add any other routes you want to exclude
];

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  config => {
    // Check if the request URL is in the excluded routes
    const isExcluded = excludedRoutes.some(route => config.url?.includes(route));

    // If the route is not excluded, add the Authorization header
    if (!isExcluded) {
      const token = localStorage.getItem('authToken');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    }

     // Attach the cancel token from the singleton manager
     const cancelTokenSource = getCancelTokenSource();
     config.cancelToken = cancelTokenSource.token;

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);


export default axiosInstance;
