import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '../../../app/store';
import { RootState } from '../../../app/store';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../components/theme';
import RadioButton from '../../../components/RadioButton';

interface SidebarProps {

}

const ThemeStyleTab: React.FC<SidebarProps> = ({ }) => {
  const { t, i18n } = useTranslation();

  const { setNavigationStyle, navigationStyle, theme, setThemeColor } = useTheme()

  const storedLanguage = localStorage.getItem('i18nextLng');
  const initialLanguage = storedLanguage || 'en';

  const dispatch: AppDispatch = useDispatch();
  const name = useState("");
  const loading = useSelector((state: RootState) => state.menu.loading);
  const error = useSelector((state: RootState) => state.menu.error);
  const location = useLocation();


  const handleNavigationStyleChange = (newStyle: string) => {
    setNavigationStyle(newStyle as any);
  };

  const handleThemeChange = (newTheme: string) => {
    setThemeColor(newTheme as any);
  };

  return (
    <>
      <div className="mb-5">
        <p className="switcher-style-head mb-2">{t('Theme Color Mode:')}</p>
        <div className="grid grid-cols-3 switcher-style">
          <div className="flex items-center">
            <RadioButton
              checked={theme === 'light'}
              onChange={() => handleThemeChange('light')}
              label={t('Light')}
              value=''
            />
          </div>
          <div className="flex items-center">
            <RadioButton
              checked={theme === 'dark'}
              onChange={() => handleThemeChange('dark')}
              label={t('Dark')}
              value=''
            />
          </div>
        </div>
      </div>

      <div className="mb-5">
        <p className="switcher-style-head mb-2">{t('Navigation Styles:')}</p>
        <div className="grid grid-cols-3 switcher-style">
          <div className="flex items-center">
            <RadioButton
              checked={navigationStyle === 'Vertical'}
              onChange={() => handleNavigationStyleChange('Vertical')}
              label={t('Vertical')}
              value=''
            />
          </div>
          <div className="flex items-center">
            <RadioButton
              checked={navigationStyle === 'Horizontal'}
              onChange={() => handleNavigationStyleChange('Horizontal')}
              label={t('Horizontal')}
              value='' />
          </div>
        </div>
      </div>

    </>
  );
};

export default ThemeStyleTab;
