import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../app/store'; // Adjust path as per your project structure
import { fetchMenuItems, selectMenuItems, MenuItem, MenuState, SubmenuItem } from '../../modules/auth/menuSlice';
import { loginUser } from '../../modules/auth/authSlice';
import { AppDispatch } from '../../app/store';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '../../components/theme';
import './HorizontalMenu.css'

interface MenuData {
    menu_name: string;
    child?: MenuData[];
}

interface HorizontalMenuProps {
    moduleName: string;
    onChangeRoute: (segment: string) => void;
}

const HorizontalMenu: React.FC<HorizontalMenuProps> = ({  moduleName, onChangeRoute }) => {
    const dispatch: AppDispatch = useDispatch();
    const scrollRef = useRef<HTMLDivElement>(null);
    const { hoverBGColor, menuColor, textColor, hoverTextColor } = useTheme();
    const [activeMenu, setActiveMenu] = useState<string | null>(null);
    const [activeSubMenu, setActiveSubMenu] = useState<string | null>(null);
    const [dropdownPosition, setDropdownPosition] = useState<{ top: number, left: number }>({ top: 0, left: 0 });
    const [nestedMenuPosition, setNestedMenuPosition] = useState<{ top: number, left: number }>({ top: 0, left: 0 });
    const [isMouseOverMenu, setIsMouseOverMenu] = useState(false);

    const menuItems = useSelector((state: RootState) => selectMenuItems(state));
    const loading = useSelector((state: RootState) => state.menu.loading);
    const error = useSelector((state: RootState) => state.menu.error);
    const location = useLocation();
    const [firstSegment, setFirstSegment] = useState<string>("");

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (!isMouseOverMenu) {
            timeoutId = setTimeout(() => {
                setActiveMenu(null);
                setActiveSubMenu(null);
            }, 300); // Delay to allow for moving to child menu
        }
        return () => clearTimeout(timeoutId);
    }, [isMouseOverMenu]);

    useEffect(() => {

        if (firstSegment !== "") {
            onChangeRoute(firstSegment)
            dispatch(fetchMenuItems({ firstSegment }));
        }
    }, [firstSegment]);

    useEffect(() => {

        const segment = location.pathname.split('/')[1];
        console.log('menuItems', segment, menuItems)

        setFirstSegment(segment);
    }, [location]);

    const scroll = (direction: 'left' | 'right') => {
        if (scrollRef.current) {
            const scrollAmount = scrollRef.current.offsetWidth - 200;
            scrollRef.current.scrollBy({
                left: direction === 'left' ? -scrollAmount : scrollAmount,
                behavior: 'smooth',
            });
        }
    };

    const handleMouseEnterMain = (label: string, event: React.MouseEvent<HTMLDivElement>) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setDropdownPosition({ top: rect.bottom + window.scrollY, left: rect.left + window.scrollX });
        setActiveMenu(label);
        setActiveSubMenu(null);
        setIsMouseOverMenu(true);
    };

    const handleMouseEnterChild = (label: string, event: React.MouseEvent<HTMLLIElement>) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setNestedMenuPosition({ top: rect.top + window.scrollY, left: rect.right + window.scrollX });
        setActiveSubMenu(label);
        setIsMouseOverMenu(true);
    };

    const handleMouseLeave = () => {
        setIsMouseOverMenu(false);
    };

    const handleMouseEnterSubMenu = () => {
        setIsMouseOverMenu(true);
    };

    return (
        <div className={`z-99999 fixed w-full mt-10 ${moduleName === 'dashboard' ? 'hidden' : ''}`} onMouseLeave={handleMouseLeave}>
            <button
                onClick={() => scroll('left')}
                className="absolute left-0 z-99999 top-1/2 transform -translate-y-1/2 bg-gray-200
                 p-2 rounded-full shadow-md hover:bg-gray-300 transition-colors duration-200"
            >
                <FontAwesomeIcon icon={faChevronLeft} className="h-5 w-5 text-gray-600" />
            </button>
            <div
                ref={scrollRef}
                className={`flex overflow-x-auto scrollbar-hide h-12 bg-${menuColor} dark:bg-black px-10 shadow-md relative`}
            >
                {menuItems.map((menu: MenuItem, index) => (
                    <div
                        key={index}
                        className={`flex-shrink-0 bg-${menuColor} hover:bg-${hoverBGColor} 
                        hover:text-${hoverTextColor} text-${textColor} dark:bg-black dark:text-colorGray
                        dark:hover:bg-colorBlack1
                         p-1 flex items-center
                         justify-center h-full cursor-pointer relative`}
                        onMouseEnter={(e) => handleMouseEnterMain(menu.name, e)}
                    >
                        <span>{menu.name}</span>
                        {menu.child && <FontAwesomeIcon icon={faAngleRight} className="ml-3" />}
                    </div>
                ))}
            </div>
            <button
                onClick={() => scroll('right')}
                className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-200
                p-2 rounded-full shadow-md hover:bg-gray-300 transition-colors duration-200"
            >
                <FontAwesomeIcon icon={faChevronRight} className="h-5 w-5 text-gray-600" />
            </button>
            {activeMenu && menuItems.find(menu => menu.name === activeMenu)?.child && (
                <ul
                    className={`dropdown-menu fixed bg-${menuColor} dark:bg-black shadow-lg z-50`}
                    style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
                    onMouseEnter={handleMouseEnterSubMenu}
                    onMouseLeave={handleMouseLeave}
                >
                    {menuItems.find(menu => menu.name === activeMenu)?.child?.map((child: MenuItem, idx) => (
                        <li
                            key={idx}
                            className={`px-4 py-3 relative group 
                            dark:hover:bg-colorBlack1 hover:text-${hoverTextColor} hover:bg-${hoverBGColor} text-${textColor} `}
                            onMouseEnter={(e) => handleMouseEnterChild(child.name, e)}
                        >

                            <Link to={child.route || '#'} className={`flex items-center text-sm text-black 
                            dark:text-white text-${textColor}
                             hover:text-${hoverTextColor}`}>
                                {child.name}
                                {child.child && child.child.length > 0 && <FontAwesomeIcon icon={faAngleRight} className="ml-3" />}
                            </Link>
                            {child.child && (child.name === activeSubMenu) && (
                                <ul
                                    className={`nested-dropdown fixed bg-${menuColor} dark:bg-black  shadow-lg z-50`}
                                    style={{ top: nestedMenuPosition.top, left: nestedMenuPosition.left }}
                                    onMouseEnter={handleMouseEnterSubMenu}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    {child.child.map((subChild, subIdx) => (
                                        <li key={subIdx} className={`px-4 py-2
                            dark:hover:bg-colorBlack1 hover:bg-${hoverBGColor} `}>

                                            <Link to={subChild.route || '#'} className={`flex items-center text-sm 
                                            dark:text-white text-${textColor}
                             hover:text-${hoverTextColor}`}>
                                                {subChild.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default HorizontalMenu;
