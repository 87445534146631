// src/features/buyer/buyerSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axiosInstance from '../../../api/axiosInstance';
import { Product, ProductResponse, ProductState } from './productInterface';
import { PaginationObject } from '../../../app/globalType';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../app/paginationConstants';
import { FormField } from '../../../components/componentInterface';

const initialState: ProductState = {
  products: [],
  product: {
    title: '',
    category_id: 0,
    is_active: false,
    is_featured: false,
    is_in_stock: true,
    is_variant: false,
    price: 0,
    vendor_id: 0,
    data: {}
  },
  paginationObject: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 1,
    last_page: 1,
    last_page_url: '',
    links: [],
    next_page_url: '',
    path: '',
    per_page: 10,
    prev_page_url: '',
    to: 1,
    total: 0,
  },
  loading: false,
  error: null,
  status: 'idle',
  message: "",
  object: {
    message: "",
    product: {
      title: '',
      category_id: 0,
      is_active: false,
      is_featured: false,
      is_in_stock: true,
      is_variant: false,
      price: 0,
      vendor_id: 0,
      data: {}
    }
  }
};

// Async thunk for fetching buyers
export const fetchProducts = createAsyncThunk(
  'product/fetchProducts',
  async ({ page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE }: { page?: number; perPage?: number }, { rejectWithValue }) => {
    
    try {
      const response = await axiosInstance.get(`/product-list?page=${page}&perPage=${perPage}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchAllProducts = createAsyncThunk(
  'product/fetchAllProducts',
  async () => {
    
    try {
      const response = await axiosInstance.get(`/product-list-all`);
      return response.data;
    } catch (error: any) {
      return Error(error.response?.data?.message || error.message);
    }
  }
);

export const fetchProduct = createAsyncThunk(
  'product/fetchProduct',
  async ({ product_id }: { product_id: string }, { rejectWithValue }) => {
    
    try {
      const response = await axiosInstance.get(`/product-show/${product_id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for creating a buyer
export const createProductStepTwo = createAsyncThunk(
  'product/createProductStepTwo',
  async (formState: any, { rejectWithValue }) => {
    try {

      const { variants, product, attributes } = formState;

      
      const formData = new FormData();

      // Append each field in teacherData to the FormData object
      for (const key in product) {
        if (key === 'image' && Array.isArray(product[key])) {
          // If it's the files field, cast it to an array of File objects and append each file individually
          (product[key] as File[]).forEach((file, index) => {
            formData.append(`product[display_images][${index}]`, file);
          });
        } else if (key === 'hover_image' && Array.isArray(product[key])) {
          // If it's the files field, cast it to an array of File objects and append each file individually
          (product[key] as File[]).forEach((file, index) => {
            formData.append(`product[hover_image][${index}]`, file);
          });
        } else if (key === 'is_all_variant_same_price') {
          formData.append(`product[${key}]`, ((product as any)[key] ? "1" : "0"));
        } else {
          formData.append(`product[${key}]`, (product as any)[key]);
        }
      }

      for (const variant_key in variants) {
        const variant_:any = variants[variant_key]
      
        for (const key in variant_) {
          if (key === 'imageFiles' && Array.isArray(variant_[key])) {
            // If it's the files field, cast it to an array of File objects and append each file individually
            (variant_[key] as File[]).forEach((file, index) => {
              formData.append(`variants[${+variant_key}][imageFiles][${index}]`, file);
            });
          } else if (key === "options" && Array.isArray(variant_[key])) {
            (variant_[key] as number[]).forEach((num, index) => {
              formData.append(`variants[${+variant_key}][options][${index}]`, num.toString());
            });
          } else {
            formData.append(`variants[${+variant_key}][${key}]`, (variant_ as any)[key]);
          }
        }
      }

      for (const attr_key in attributes) {
        const attribute:any = attributes[attr_key];
        for (const key in attribute) {
          formData.append(`attributes[${+attr_key}][${key}]`, (attribute as any)[key]);
        }
      }

      // formData.append(`product`, (product as any));

      const response = await axiosInstance.post('/product-save-step-two', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // const response = await axiosInstance.post('/product-save-step-two', formState);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for creating a buyer
export const createProductStepOne = createAsyncThunk(
  'product/createProductStepOne',
  async (formState: Omit<Product, 'id'>, { rejectWithValue }) => {
    try {

      const { details, title, type, attribute_family_id, product_type,
         ...formStateData } = formState.data;

      const attributeData = {
        title: title.value,
        attribute_family_id: attribute_family_id.value,
        product_type: product_type.value
      };
      // debugger

      const response = await axiosInstance.post('/product-save-step-one', attributeData);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for creating a buyer
export const createProduct = createAsyncThunk(
  'product/createProduct',
  async (formState: Omit<Product, 'id'>, { rejectWithValue }) => {
    try {

      const { details, name, type, ...formStateData } = formState.data;

      const attributeData = {
        name: name.value,
        type: type.value,
        rows: details?.rows.map((row : any) => {
          const newRow: Partial<Product> = {
            option: ''
          };

          row?.map((cell : any) => {
            if (cell.name === 'option') {
              newRow.option = cell.type.value as string;
            } 
          })

          return newRow;

        }) || []
      };
      // debugger

      const response = await axiosInstance.post('/product-save', attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for updating a session
export const updateProduct = createAsyncThunk(
  'product/updateProduct',
  async (formState: Omit<Product, 'id'>, { rejectWithValue }) => {
    try {
      debugger
      const { details, id, name, type, ...formStateData } = formState.data;
      
      const attributeData = {
        id: id.value,
        name: name.value,
        type: type.value,
        rows: details?.rows.map((row : any) => {
          const newRow: Partial<Product> = {
            option: '',
            id: 0
          };

          row?.map((cell : any) => {
            if (cell.name === 'option') {
              newRow.option = cell.type.value as string;
            } 
            if (cell.name === 'id') {
              newRow.id = cell.type.value as number;
            } 
          })

          return newRow;

        }) || []
      };

      const response = await axiosInstance.put(`/product-update/${id}`, attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for deleting a session
export const deleteProduct = createAsyncThunk(
  'product/deleteProduct',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/product-delete/${id}`);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    resetForm(state) {
      Object.assign(state, initialState);
    },
    resetMessage(state) {
      state.message = "";
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action:  PayloadAction<PaginationObject<Product>>) => {
        state.paginationObject = action.payload;
        state.loading = false;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchAllProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllProducts.fulfilled, (state, action:  PayloadAction<Product[]>) => {
        state.products = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProduct.fulfilled, (state, action:  PayloadAction<Product>) => {
        state.product = action.payload;
        state.loading = false;
      })
      .addCase(fetchProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(createProductStepOne.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createProductStepOne.fulfilled, (state, action: PayloadAction<ProductResponse>) => {
        state.loading = false;
        state.object = action.payload
      })
      .addCase(createProductStepOne.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(createProductStepTwo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createProductStepTwo.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload
      })
      .addCase(createProductStepTwo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })


      .addCase(createProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createProduct.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload
      })
      .addCase(createProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(updateProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProduct.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(deleteProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteProduct.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

  },
});

export const { resetForm, resetMessage, resetError } = productSlice.actions;

export default productSlice.reducer;
