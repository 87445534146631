import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars, faSearch, faBell as faSolidBell, faCommentDots, faUser, faCog,
  faQuestionCircle, faSignOutAlt, faMoon, faExpand, faBellSlash,
  faCompress,
  faLightbulb,
  faSun
} from '@fortawesome/free-solid-svg-icons';
import profile_img from '../../img/profile-img.jpg'
import './Navbar.css'
import { logout } from '../../modules/auth/authSlice';
import SettingsIcon from '../../components/SettingsIcon';
import { useTheme } from '../../components/theme';
import SidebarBranding from './SidebarBranding';

interface TopNavProps {
  onToggleSidebar: () => void;
  settingClick: () => void;
  moduleName: string;
  isSideBarOpen: boolean;
  isClosedSidebarOpen: boolean;
}

const Navbar: React.FC<TopNavProps> = ({ onToggleSidebar, moduleName, isSideBarOpen, settingClick, isClosedSidebarOpen }) => {
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());

    navigate('/login');
  };

  const handleMenuBarClick = () => {
    onToggleSidebar();
  };

  const handleSettingsClick = () => {
    settingClick()
    // setIsRightSidebarOpen(!isRightSidebarOpen)
  };


  const [showNotifications, setShowNotifications] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  const profileRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (profileRef.current && !profileRef.current.contains(event.target as Node)) {
        setShowProfile(false);
      }
    };

    if (showProfile) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showProfile]);

  const toggleProfile = () => setShowProfile(!showProfile);

  const [fullScreen, setFullScreen] = useState(false);

  const toggleFullScreen = () => {
    const elem = document.documentElement;

    if (!document.fullscreenElement) {
      elem.requestFullscreen().then(() => setFullScreen(true));
    } else {
      document.exitFullscreen().then(() => setFullScreen(false));
    }
  };


  const handleFullscreenChange = () => {
    setFullScreen(!!document.fullscreenElement);
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const { theme, toggleTheme, setThemeColor, topNavColor, navigationStyle } = useTheme();

  return (
    <nav className={`bg-${topNavColor} dark:bg-colorBlack fixed-top  
    ${navigationStyle === 'Horizontal' ? "sidespan-open-full" :
        (isSideBarOpen ? "sidespan-open" : (isClosedSidebarOpen ? "sidespan-open" : "sidespan-close"))} 
    ${moduleName}`}>
      <div className="container">
        <div className="flex justify-between items-center">

          {
            navigationStyle === 'Vertical' &&
            <div className="flex items-center">
              <FontAwesomeIcon onClick={handleMenuBarClick} icon={faBars} className={`cursor-pointer text-gray-600  ml-2
              ${moduleName === "dashboard" ? " hidden " : ""}  ${isClosedSidebarOpen ? " hidden " : ""} `} />
            </div>
          }

          {
            navigationStyle === 'Horizontal' && 
            <SidebarBranding
            isClosedSidebarOpen={isClosedSidebarOpen}
            isOpen={isSideBarOpen}
            />
          }


          {/* Navigation Links */}
          <div className="flex items-center ml-auto ">
            <FontAwesomeIcon aria-label="anchor" onClick={toggleTheme} icon={theme === "dark" ? faSun : faMoon} style={{ color: 'grey' }} className="text-grey mr-5 cursor-pointer" />
            <FontAwesomeIcon aria-label="anchor" icon={faSolidBell} amplitude={0} fillOpacity={.5} style={{ color: 'grey' }} className="text-grey mr-5 cursor-pointer" />
            <FontAwesomeIcon aria-label="anchor" onClick={toggleFullScreen}
              icon={fullScreen ? faCompress : faExpand} style={{ color: 'grey' }} className="text-grey mr-5 cursor-pointer" />
            {/* Dropdown Menu */}
            <div className="relative" ref={profileRef}>
              <a href="#" className="nav-link nav-profile flex items-center" aria-label="Profile" aria-haspopup="true"
                onClick={toggleProfile}>
                <img src={profile_img} alt="Profile" className="rounded-full h-10 w-10 object-cover" />
                <span className="ml-2 hidden md:block text-colorBlack dark:text-colorWhite">K. Anderson</span>
              </a>
              {/* Dropdown Menu */}
              {showProfile && (
                <ul className="dropdown-menu profile dropdown-menu-end dropdown-menu-arrow profile 
                absolute bg-colorWhite dark:bg-colorBlack rounded-md mt-2 w-80 shadow-clWhite dark:shadow-black">
                  <li className="dropdown-header">
                    <h6 className="font-semibold text-colorBlack dark:text-colorWhite">Kevin Anderson</h6>
                    <span className="text-xs text-gray-600">Web Designer</span>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  {/* Profile Options */}
                  <li className="px-4 py-3">
                    <a href="#" className="flex items-center text-sm text-colorBlack dark:text-colorWhite hover:text-gray-900">
                      <FontAwesomeIcon icon={faUser} className="text-gray-600" />
                      <span className="ml-2">My Profile</span>
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="px-4 py-3">
                    <a href="#" className="flex items-center text-sm text-colorBlack dark:text-colorWhite hover:text-gray-900">
                      <FontAwesomeIcon icon={faCog} className="text-gray-600" />
                      <span className="ml-2">Account Settings</span>
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="px-4 py-3">
                    <a href="#" className="flex items-center text-sm text-colorBlack dark:text-colorWhite hover:text-gray-900">
                      <FontAwesomeIcon icon={faQuestionCircle} className="text-gray-600" />
                      <span className="ml-2">Need Help?</span>
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  {/* Sign Out */}
                  <li className="px-4 py-3">
                    <a href="#" onClick={handleLogout} className="flex items-center text-sm text-colorBlack dark:text-colorWhite hover:text-gray-900">
                      <FontAwesomeIcon icon={faSignOutAlt} className="text-gray-600" />
                      <span className="ml-2">Sign Out</span>
                    </a>
                  </li>
                </ul>
              )}
            </div>
            <SettingsIcon onClick={handleSettingsClick} className="settings-icon-custom ml-2 mr-5 cursor-pointer" />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
