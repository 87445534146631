// src/features/buyer/buyerSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axiosInstance from '../../../api/axiosInstance';
import { FooterLink, FooterLinkState } from './footerLinkPagesInterface';

import { PaginationObject } from '../../../app/globalType';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../app/paginationConstants';
import { FormField } from '../../../components/componentInterface';

const initialState: FooterLinkState = {
  footerLinks: [],
  footerLink: {
    content: '',
    label: '',
    type: '',
    url: '',
    section: '',
    data: {}
  },
  paginationObject: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 1,
    last_page: 1,
    last_page_url: '',
    links: [],
    next_page_url: '',
    path: '',
    per_page: 10,
    prev_page_url: '',
    to: 1,
    total: 0,
  },
  loading: false,
  error: null,
  status: 'idle',
  message: ""
};

// Async thunk for fetching buyers
export const fetchFooterLinks = createAsyncThunk(
  'footerLink/fetchFooterLinks',
  async ({ page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE }: { page?: number; perPage?: number }, { rejectWithValue }) => {
    
    try {
      const response = await axiosInstance.get(`/footer-list?page=${page}&perPage=${perPage}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchAllFooterLinks = createAsyncThunk(
  'footerLink/fetchAllFooterLinks',
  async () => {
    
    try {
      const response = await axiosInstance.get(`/footer-list-all`);
      return response.data;
    } catch (error: any) {
      return Error(error.response?.data?.message || error.message);
    }
  }
);

export const fetchFooterLink = createAsyncThunk(
  'footerLink/fetchFooterLink',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    
    try {
      const response = await axiosInstance.get(`/footer-show/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for creating a buyer
export const createFooterLink = createAsyncThunk(
  'footerLink/createFooterLink',
  async (formState: Omit<FooterLink, 'id'>, { rejectWithValue }) => {
    try {
  
      const { label, url, type, section, content } = formState.data;
      
      const attributeData = {
        content: content.value,
        label: label.value,
        url: url.value,
        type: type.value,
        section: section.value,
      };
      // debugger

      const response = await axiosInstance.post('/footer-save', attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for updating a session
export const updateFooterLink = createAsyncThunk(
  'footerLink/updateFooterLink',
  async (formState: Omit<FooterLink, 'id'>, { rejectWithValue }) => {
    try {
      const { label, id, url, type, section, content } = formState.data;
      debugger
      const attributeData = {
        id: id.value,
        content: content.value,
        label: label.value,
        url: url.value,
        type: type.value,
        section: section.value,
      };

      const response = await axiosInstance.put(`/footer-update/${attributeData.id}`, attributeData);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for deleting a session
export const deleteFooterLink = createAsyncThunk(
  'footerLink/deleteFooterLink',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/footer-delete/${id}`);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const attributeSlice = createSlice({
  name: 'footerLink',
  initialState,
  reducers: {
    resetForm(state) {
      Object.assign(state, initialState);
    },
    resetMessage(state) {
      state.message = "";
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFooterLinks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFooterLinks.fulfilled, (state, action:  PayloadAction<PaginationObject<FooterLink>>) => {
        state.paginationObject = action.payload;
        state.loading = false;
      })
      .addCase(fetchFooterLinks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchAllFooterLinks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllFooterLinks.fulfilled, (state, action:  PayloadAction<FooterLink[]>) => {
        state.footerLinks = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllFooterLinks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchFooterLink.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFooterLink.fulfilled, (state, action:  PayloadAction<FooterLink>) => {
        state.footerLink = action.payload;
        state.loading = false;
      })
      .addCase(fetchFooterLink.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(createFooterLink.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createFooterLink.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload
      })
      .addCase(createFooterLink.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(updateFooterLink.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateFooterLink.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(updateFooterLink.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(deleteFooterLink.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteFooterLink.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(deleteFooterLink.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

  },
});

export const { resetForm, resetMessage, resetError } = attributeSlice.actions;

export default attributeSlice.reducer;
