// src/components/AuthGuard.tsx
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../app/store';
import { Navigate } from 'react-router-dom';
import Sidebar from '../layout/shared/Sidebar';
import '../index.css';
import Navbar from '../layout/shared/Navbar';
import BodyClassWrapper from './BodyClassWrapper';
import SettingsIcon from './SettingsIcon';
import RightSidebar from '../layout/shared/RightSidebar';
import withRequestCancellation from './withRequestCancellation';
import HorizontalMenu from '../layout/shared/HorizontalMenu';
import { useTheme } from './theme';

type AuthGuardProps = {
  children: React.ReactNode;
};

const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState<boolean>(false);
  const [moduleName, setModuleName] = useState<string>("");
  const [isClosedSidebarOpen, setClosedSidebarOpen] = useState<boolean>(false);

  const { navigationStyle } = useTheme();

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  const handleOnChangeRoute = (segment: string) => {
    if (segment === "dashboard") {
      setIsSidebarOpen(false)
      setModuleName(segment)
    } else {
      setIsSidebarOpen(true)
      setModuleName(segment)
    }
  };

  const handleSettingsClick = () => {
    setIsRightSidebarOpen(!isRightSidebarOpen)
  };

  const handleClosesSidebarMouseHover = (condition: boolean) => {
    setClosedSidebarOpen(condition)
  };


  return <>
    <BodyClassWrapper className="bg-gray-100">

      <RightSidebar isOpen={isRightSidebarOpen} onClickClose={handleSettingsClick} />
      {
        navigationStyle === 'Vertical' &&
        <Sidebar isOpen={isSidebarOpen}
          onChangeRoute={handleOnChangeRoute}
          onMouseHoverOnCloseSidebar={handleClosesSidebarMouseHover}
          moduleName={moduleName} />
      }

      <Navbar
        onToggleSidebar={handleToggleSidebar}
        moduleName={moduleName}
        isClosedSidebarOpen={isClosedSidebarOpen}
        settingClick={handleSettingsClick}
        isSideBarOpen={isSidebarOpen} />

      {
        (navigationStyle === 'Horizontal') &&
        <HorizontalMenu
          onChangeRoute={handleOnChangeRoute}
          moduleName={moduleName} />
      }


      <div className={`h-screen flex flex-row flex-wrap bg-colorGray dark:bg-colorBlack1 
        ${navigationStyle === 'Horizontal' ? 'dashboard' : (isSidebarOpen ? 'sidebar-open' : 'sidebar-closed')} 
        ${moduleName}`} id='main'>
        <main className={`bg-colorGray dark:bg-colorBlack1 flex-1 p-6  ${navigationStyle === 'Horizontal' ? 'mt-20 md:mt-20' : ' mt-6 md:mt-8'}`}>
          {children}
        </main>
      </div>

    </BodyClassWrapper>
  </>;
};

export default AuthGuard;
