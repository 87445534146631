// src/features/buyer/buyerSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axiosInstance from '../../../api/axiosInstance';
import { HomeSlider, HomeSliderState } from './homeSliderInterface';
import { PaginationObject } from '../../../app/globalType';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../app/paginationConstants';
import { FormField } from '../../../components/componentInterface';

const initialState: HomeSliderState = {
  homeSliders: [],
  homeSlider: {
    description: '',
    image: '',
    link: '',
    sort_no: '',
    title: '',
    data: {}
  },
  paginationObject: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 1,
    last_page: 1,
    last_page_url: '',
    links: [],
    next_page_url: '',
    path: '',
    per_page: 10,
    prev_page_url: '',
    to: 1,
    total: 0,
  },
  loading: false,
  error: null,
  status: 'idle',
  message: ""
};

// Async thunk for fetching buyers
export const fetchHomeSliders = createAsyncThunk(
  'homeSlider/fetchHomeSliders',
  async ({ page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE }: { page?: number; perPage?: number }, { rejectWithValue }) => {
    
    try {
      const response = await axiosInstance.get(`/home-slider-list?page=${page}&perPage=${perPage}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);


export const fetchHomeSlider = createAsyncThunk(
  'homeSlider/fetchHomeSlider',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    
    try {
      const response = await axiosInstance.get(`/home-slider-show/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for creating a buyer
export const createHomeSlider = createAsyncThunk(
  'homeSlider/createHomeSlider',
  async (formState: any, { rejectWithValue }) => {
    try {

      const { description, title, sort_no, image, link } = formState.data;
      
      const formData = new FormData();

      const sldierData = {
        description: description.value,
        title: title.value,
        sort_no: sort_no.value,
        image: image.value,
        link: link.value,
      };
      // debugger

      for (const key in sldierData) {
        if (key === 'image' && Array.isArray(sldierData[key])) {
          // If it's the files field, cast it to an array of File objects and append each file individually
          (sldierData[key] as File[]).forEach((file, index) => {
            formData.append(`image`, file);
          });
        } else {
          formData.append(`${key}`, (sldierData as any)[key]);
        }
        
      }

      const response = await axiosInstance.post('/home-slider-save', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for updating a session
export const updateHomeSlider = createAsyncThunk(
  'homeSlider/updateHomeSlider',
  async (formState: Omit<HomeSlider, 'id'>, { rejectWithValue }) => {
    try {
      const { description, id, title, sort_no, image, link } = formState.data;
      
      const formData = new FormData();

      const sldierData = {
        id: id.value,
        description: description.value,
        title: title.value,
        sort_no: sort_no.value,
        image: image.value,
        link: link.value,
      };
      // debugger

      for (const key in sldierData) {
        if (key === 'image' && Array.isArray(sldierData[key])) {
          // If it's the files field, cast it to an array of File objects and append each file individually
          (sldierData[key] as File[]).forEach((file, index) => {
            formData.append(`image`, file);
          });
        } else {
          formData.append(`${key}`, (sldierData as any)[key]);
        }
        
      }

      const response = await axiosInstance.post('/home-slider-update/'+sldierData.id, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for deleting a session
export const deleteHomeSlider = createAsyncThunk(
  'homeSlider/deleteHomeSlider',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/home-slider-delete/${id}`);
      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const attributeSlice = createSlice({
  name: 'homeSlider',
  initialState,
  reducers: {
    resetForm(state) {
      Object.assign(state, initialState);
    },
    resetMessage(state) {
      state.message = "";
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeSliders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHomeSliders.fulfilled, (state, action:  PayloadAction<PaginationObject<HomeSlider>>) => {
        state.paginationObject = action.payload;
        state.loading = false;
      })
      .addCase(fetchHomeSliders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchHomeSlider.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHomeSlider.fulfilled, (state, action:  PayloadAction<HomeSlider>) => {
        state.homeSlider = action.payload;
        state.loading = false;
      })
      .addCase(fetchHomeSlider.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(createHomeSlider.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createHomeSlider.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload
      })
      .addCase(createHomeSlider.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(updateHomeSlider.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateHomeSlider.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(updateHomeSlider.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(deleteHomeSlider.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteHomeSlider.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(deleteHomeSlider.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

  },
});

export const { resetForm, resetMessage, resetError } = attributeSlice.actions;

export default attributeSlice.reducer;
